// Custom.scss
// Option A: Include all of Bootstrap

:root {
  --es-primary-green: #054233;
  --es-primary-yellow: #f2d64f;
  --es-primary-white: #ffffff;

  --es-secondary-green-darker: #81a098;
  --es-secondary-green: #c0cfcc;
  --es-secondary-green-lighter: #dfe7e5;
  --es-secondary-yellow: #f7e8a6;

  --es-tertionary-red: #fc9161;
  --es-tertionary-blue: #05dee5;
  --es-tertionary-green: #4aae62;

  --es-secondary-yellow-faded: #efe8c85c;
}

$es-primary-green: #054233;
$es-primary-yellow: #f2d64f;
$es-primary-white: #ffffff;

$es-secondary-green-darker: #81a098;
$es-secondary-green: #c0cfcc;
$es-secondary-green-lighter: #dfe7e5;
$es-secondary-yellow: #f7e8a6;

$es-tertionary-red: #fc9161;
$es-tertionary-blue: #05dee5;
$es-tertionary-green: #4aae62;

// Include any default variable overrides here (though functions won't be available)

$primary: $es-primary-green;
$secondary: $es-primary-yellow;
$success: $es-tertionary-green;
$info: $es-tertionary-blue;
$warning: $es-tertionary-red;
$danger: $es-tertionary-red;
$light: $es-secondary-green-lighter;
$dark: $es-secondary-green-darker;

$body-color: $primary;

$font-family-sans-serif: "Roboto", sans-serif;
$font-family-monospace: "Roboto Mono", monospace;

$font-weight-base: 300;

$headings-font-weight: 700;

$display-font-sizes: (
  1: 5rem * 0.9,
  2: 4.5rem * 0.9,
  3: 4rem * 0.9,
  4: 3.5rem * 0.9,
  5: 3rem * 0.9,
  6: 2.5rem * 0.9,
);
$display-font-weight: 400;

$spacer: 1.5rem;

$nav-link-font-size: 0.875rem;
$nav-link-font-weight: 400;

$nav-link-color: #fff;
$nav-link-hover-color: #fff;

$btn-padding-x: 1.5rem;

@import "../scss/bootstrap";

// Then add additional custom code here

.es-bg-green {
  background-color: $es-primary-green;
}

.es-bg-yellow {
  background-color: $es-primary-yellow;
}

.es-bg-white {
  background-color: $es-primary-white;
}

.es-bg-secondary-green-darker {
  background-color: $es-secondary-green-darker;
}

.es-bg-secondary-green {
  background-color: $es-secondary-green;
}

.es-bg-secondary-green-lighter {
  background-color: $es-secondary-green-lighter;
}

.es-bg-secondary-yellow {
  background-color: $es-secondary-yellow;
}

.es-bg-tertionary-red {
  background-color: $es-tertionary-red;
}

.es-bg-tertionary-blue {
  background-color: $es-tertionary-blue;
}

.es-bg-tertionary-green {
  background-color: $es-tertionary-green;
}

.es-fg-green {
  color: $es-primary-green;
}

.es-fg-yellow {
  color: $es-primary-yellow;
}

.es-fg-white {
  color: $es-primary-white;
}

.es-fg-secondary-green-darker {
  color: $es-secondary-green-darker;
}

.es-fg-secondary-green {
  color: $es-secondary-green;
}

.es-fg-secondary-green-lighter {
  color: $es-secondary-green-lighter;
}

.es-fg-secondary-yellow {
  color: $es-secondary-yellow;
}

.es-fg-tertionary-red {
  color: $es-tertionary-red;
}

.es-fg-tertionary-blue {
  color: $es-tertionary-blue;
}

.es-fg-tertionary-green {
  color: $es-tertionary-green;
}

.es-bg-card {
  background-color: var(--es-secondary-yellow-faded);
}

.es-bg-card:hover {
  background-color: var(--es-secondary-yellow);
}
